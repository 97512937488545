import React, {useState, useEffect}  from 'react';
import './App.css';
import {ButtonBar} from './components/button-bar';
import {Instructions} from './components/instructions';
import {MediaDeviceSelector} from './components/media-device-selector';
import {MediaHandler} from './components/media-handler';
import {MediaError} from './components/media-error';
import { mouseWheelKeyboardZoom } from './library/mouse-wheel-keyboard-zoom';
const classNames = require('classnames');
let oldKeyUp = null;

function App() {
  const [phase, setPhase] = useState('welcome');
  const [isPlayingInstructions, setIsPlayingInstructions] = useState(false);
  const [isShowingInstructions, setIsShowingInstructions] = useState(false);
  const [stream, setStream] = useState(null);
  const [videoDevice, setVideoDevice] = useState(null);
  const [resolution, setResolution] = useState(null);
  const [isInverted, setInverted] = useState(false);
  const [isGrayScale, setGrayScale] = useState(false);
  const [isContrast, setContrast] = useState(false);
  const [isBright, setBright] = useState(false);

  const playInstructions = () => {setIsPlayingInstructions(true);}
  const stopPlayingInstructions = () => {setIsPlayingInstructions(false);}
  
  const showInstructions = () => {setIsShowingInstructions(true);}
  const hideInstructions = () => {setIsShowingInstructions(false);}

  // Keyboard shortcuts
  const onKeyUp = function (e) {
    const isR = e.key=='r' || e.key == 'R';
    const isI = e.key=='i' || e.key == 'I';
    const isG = e.key=='g' || e.key == 'G';
    const isC = e.key=='c' || e.key == 'C';
    const isB = e.key=='b' || e.key == 'B';
    const isP = e.key=='p' || e.key == 'P';

    if(isI || isR || isG || isC || isB || isP) {
      e.preventDefault();
    }

    // reload
    if(isR) {
      setInverted(0);
      setGrayScale(0);
      setContrast(0);
      setBright(0);
    }

    // invert colors
    if(isI) {
      setInverted(!isInverted);
    }

    // grayscale
    if(isG) {
      if(isGrayScale == 1) setGrayScale(0);
      else setGrayScale(isGrayScale+1);
    }

    // contrast
    if(isC) {
      if(isContrast == 9) setContrast(0);
      else setContrast(isContrast+1);
    }

    // bright
    if(isB) {
      if(isBright == 9) setBright(0);
      else setBright(isBright+1);
    }

    // position
    if(isP) {
      document.wz.reset();
    }
  };

  useEffect( () => {
    window.removeEventListener('keyup', oldKeyUp);  
    window.addEventListener('keyup', onKeyUp);
    oldKeyUp = onKeyUp;
  }, [isInverted, isGrayScale, isContrast, isBright]);

  // When resolution is not null, everything is ready
  useEffect( () => {
    if(!resolution)
      return;

    const wz = mouseWheelKeyboardZoom({
      element: document.querySelector('.video-view'),
      zoomStep: .1
    });
    
    document.wz = wz;
  }, [resolution]);

  if(phase == "media-error") {
    return <MediaError />;
  }

  return (
    <div className={classNames({"App": true })}>
      <div className={classNames({ 'inverted': isInverted })}>
        <div className={classNames({ ['gray-scale-'+isGrayScale]: true })}>
          <div className={classNames({['contrast-'+isContrast]: true })}>
            <div className={classNames({['bright-'+isBright]:  true})}>
              <ButtonBar { ... { phase, setPhase, isPlayingInstructions, playInstructions, stopPlayingInstructions, isShowingInstructions, showInstructions, hideInstructions} }></ButtonBar>
              {(phase=='welcome' && isShowingInstructions) ? <Instructions></Instructions> : null }
              {(phase=='welcome' && isPlayingInstructions) ? <audio src="instructions.mp3" autoPlay={true} type="audio/mp3" /> : null }
              {videoDevice == null ? <MediaDeviceSelector { ...{phase, setPhase, videoDevice, setVideoDevice} } /> : null }
              <MediaHandler { ...{phase, setPhase, stream, setStream, videoDevice, resolution, setResolution} } />
              {resolution ? <div id="resolution">{resolution.label}</div> : null }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
