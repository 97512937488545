import React, { Fragment } from 'react';

export function MediaError (props) {
    return <div>
    <div class="error">
      ERROR - you need to grant permissions to browser.
    </div>
    <button onClick={() => {
      document.location.href = document.location.href.replace(document.location.search, "") + "?1=" + (new Date()).getTime();
    }}>Try again</button>
  </div>
}