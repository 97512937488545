import React, { Fragment, useEffect, useRef, useState } from 'react';

export function MediaDeviceSelector (props) {
  const videoRef = useRef();
  const {phase, setVideoDevice} = props;

  const [deviceList, setDeviceList] = useState([]);

  // List devices
  useEffect( () => {
    const {phase, setPhase} = props;
    const {setStream} = props;
    const {videoDevice} = props;

    if(phase == 'starting') {
      navigator.mediaDevices.getUserMedia({video: true, audio: false}).then( () => 
        navigator.mediaDevices.enumerateDevices().then( devices => {
          const webcams = devices.filter( device => device.kind == 'videoinput' );
          console.log("webcams", webcams);
          if(webcams.length == 1) {
            setVideoDevice(webcams[0].deviceId);
          } else {
            setDeviceList(webcams);
          }
        } )
        .catch( () => setPhase("media-error") )
      ).catch( () => setPhase("media-error") )
    }
  }, [phase]);

  return deviceList.length ? <select onChange={ (event) => setVideoDevice(event.target.value)} >
      <option>Please select device</option>
      { deviceList.map( device => <option key={device.deviceId} value={device.deviceId}>{device.label}</option>) }
  }) </select> : null;
}