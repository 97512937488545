import React, { Fragment } from 'react';

export function Instructions (props) {
    return <div id="instructions">
    <h1>Magnified view</h1>
    <h2>Instructions</h2>
    <dl>
        <dt>I</dt>
        <dd>Invert</dd>
        <dt>G</dt>
        <dd>Grayscale</dd>
        <dt>C</dt>
        <dd>Contrast [1-9]</dd>
        <dt>B</dt>
        <dd>Brightness [1-9]</dd>
        <dt>R</dt>
        <dd>Reset colors</dd>
        <dt>P</dt>
        <dd>Reset position</dd>
        <dt>+</dt>
        <dd>Zoom in</dd>
        <dt>-</dt>
        <dd>Zoom out</dd>
        <dt>0</dt>
        <dd>Reset zoom</dd>
    </dl>
  </div>;
}