import React, { Fragment, useEffect, useRef, useState } from 'react';
import {resolutions} from '../constants/resolutions';

export function MediaHandler (props) {
  const videoRef = useRef();
  const {stream, setResolution} = props;
  const [resolutionIndex, setResolutionIndex] = useState(0);

  // Monitor phase
  useEffect( () => {
    const {phase, setPhase} = props;
    const {setStream} = props;
    const {videoDevice} = props;

    if(phase == 'starting' && videoDevice) {
      const resolution = resolutions[resolutionIndex];

      setTimeout( () => {
        navigator.mediaDevices.getUserMedia({
            audio: false, 
            video: { 
              deviceId: { exact: videoDevice } , 
              width: { exact: resolution.width }, 
              height: { exact: resolution.height } 
            }
          } )
          .then( (obtainedStream) => {
            setStream(obtainedStream);
            console.log("Success with resolution ", resolution);
            setResolution(resolution);
          } )
          .catch( () => {
            console.error("Failure with resolution ", resolution);
            //Try next resolution
            if(resolutionIndex < resolutions.length - 1)
              setResolutionIndex(resolutionIndex+1);
            else // no more resolutions
              setPhase("media-resolution-error");
          });
        }, 500);
      }
  }, [props.phase, props.videoDevice, resolutionIndex]);

  // Monitor stream
  useEffect( () => {
    const {stream} = props;
    if(stream) {
      const videoObject = videoRef.current;
      videoObject.srcObject = stream;
      videoObject.play();
    }
  }, [stream]);

  return stream ? <video ref={videoRef} className="video-view"></video> : null;
}