import React, { Fragment } from 'react';

export function ButtonBar (props) {
    return <div>
        {
        (props.phase == 'welcome')?<Fragment>
            {!props.isPlayingInstructions ? 
                    <button onClick={() => {props.playInstructions()}}>Play instructions</button>
                :   <button onClick={() => {props.stopPlayingInstructions()}}>Stop playing instructions</button>
            }
            {!props.isShowingInstructions ? 
                    <button onClick={() => {props.showInstructions()}}>Show instructions</button>
                :   <button onClick={() => {props.hideInstructions()}}>Hide instructions</button>
            }

            <button onClick={() => {props.setPhase('starting')}}>Start</button>
        </Fragment>:null
        }
    </div>
}